import service from './request'
//接口前缀
const BASE_PREFIX = '/base';
/*-------------------提交工单 -----------------------*/
const submitWorkOrder = (data) => {
  return service({
    url: '/fortune/approval/submit',
    method: 'post',
    data,
    formData:true,
  })
}
const getPlateColorByLiscense = (data)=>{
  return service({
    url:'/fortune/work/order/queryLicensePlateColorByCphEq',
    method:'get',
    data
  })
}

/**---------------------终端配置--------------------- */
//可选终端配置列表
const getTerminalList = (data)=>{
  return service({
    url:`${BASE_PREFIX}/dataforward/config/queryList`,
    method:'post',
    data
  })
}
//保存终端配置
const saveDevice = (data)=>{
  return service({
    url:`${BASE_PREFIX}/dataforward/config/saveDevice`,
    method:'post',
    data
  })
}
/* ----------------- 登录模块 ---------------------- */
// 用户登录
const login = data => {
  return service({
    url: `${BASE_PREFIX}/user/login`,
    method: 'post',
    data
  })
}

// 用户登出
const loginOut = data => {
  return service({
    url: `${BASE_PREFIX}/user/logout`,
    method: 'get',
    data
  })
}

// 查询所有新闻
const queryNews = data => {
  return service({
    url: `${BASE_PREFIX}/news/queryNews`,
    method: 'get',
    data
  })
}

// 获取用户设置经纬度
const getUserLnlat = data => {
  return service({
    url: `${BASE_PREFIX}/company/queryCompanyByLoginCompanys`,
    method: 'post',
    data
  })
}
//模拟上线-车辆过滤
const getSimulateVehicle = data => {
  return service({
    url: `${BASE_PREFIX}/simulate/querySimulateVehicle`,
    method: 'get',
    data
  })
}
//模拟上线
const simulateVehicleOnline = data => {
  return service({
    url: `${BASE_PREFIX}/simulate/simulateVehicleOnline`,
    method: 'post',
    data
  })
}
//809轨迹补传
const travelPatch = data => {
  return service({
    url: `${BASE_PREFIX}/travelSupplement/travelPatch`,
    method: 'post',
    data
  })
}

export {
  login,
  loginOut,
  queryNews,
  getUserLnlat,
  submitWorkOrder,
  getPlateColorByLiscense,
  getTerminalList,
  saveDevice,
  getSimulateVehicle,
  simulateVehicleOnline,
  travelPatch
}

/* ----------------- 登录模块 ---------------------- */

/* ----------------- 用户模块 ---------------------- */

// 获取用户列表
const getUserList = data => {
  return service({
    url: '/base/user/queryUser',
    method: 'post',
    data
  })
}

// 禁用|启用 用户
const disableUser = data => {
  return service({
    url: '/base/user/updateStatus',
    method: 'get',
    data
  })
}

// 新增用户
const insertUser = data => {
  return service({
    url: '/base/user/add',
    method: 'post',
    data
  })
}

// 修改用户信息
const updateUser = data => {
  return service({
    url: '/base/user/update',
    method: 'put',
    data
  })
}

// 查询报警弹窗设置
const getAlarm = data => {
  return service({
    url: '/base/user/getAlarm',
    method: 'get',
    data
  })
}

// 报警弹窗设置
const updateAlarm = data => {
  return service({
    url: '/base/user/updateAlarm',
    method: 'put',
    data
  })
}

// 重置密码
const resetPwd = data => {
  return service({
    url: '/base/user/resetPwd',
    method: 'get',
    data
  })
}
//解锁账号
const unLockUser = data => {
  return service({
    url: '/base/user/unLockUser',
    method: 'get',
    data
  })
}
// 修改密码
const updatePassword = data => {
  return service({
    url: '/base/user/updatePassword',
    method: 'get',
    data
  })
}

// 车组账号-查询绑定车辆数据
const getUserVehicleList = data => {
  return service({
    url: '/base/userVehicle/getUserVehicleList',
    method: 'get',
    data
  })
}

// 车组账号-绑定车辆
const bindUserVehicles = data => {
  return service({
    url: '/base/userVehicle/bindUserVehicles',
    method: 'post',
    data
  })
}
// 车组账号-解除绑定
const unbindUserVehicles = data => {
  return service({
    url: '/base/userVehicle/del',
    method: 'post',
    data
  })
}
//小程序是否可选
const queryWeChatChoose = data => {
  return service({
    url: '/base/user/queryWeChatChoose',
    method: 'get',
    data
  })
}


export {
  getUserList,
  disableUser,
  insertUser,
  updateUser,
  getAlarm,
  updateAlarm,
  resetPwd,
  unLockUser,
  updatePassword,
  getUserVehicleList,
  bindUserVehicles,
  unbindUserVehicles,
  queryWeChatChoose
}

/* ----------------- 用户模块 ---------------------- */

/* ----------------- 菜单模块 ---------------------- */

// 新增菜单
const insertMenu = data => {
  return service({
    url: '/base/menu/add',
    method: 'post',
    data
  })
}

// 查询用户菜单树
const getMenus = data => {
  return service({
    url: '/base/menu/getMenus',
    method: 'get',
    data
  })
}

// 根据父级id查询菜单
const queryMenu = data => {
  return service({
    url: 'base/menu/getByParentId',
    method: 'get',
    data
  })
}

// 删除菜单
const deleteMenu = data => {
  return service({
    url: `/base/menu/delete/${data}`,
    method: 'delete'
  })
}

// 修改菜单
const updateMenu = data => {
  return service({
    url: '/base/menu/update',
    method: 'put',
    data
  })
}

export {
  insertMenu,
  getMenus,
  queryMenu,
  deleteMenu,
  updateMenu
}

/* ----------------- 菜单模块 ---------------------- */

/* ----------------- 用户组模块 ---------------------- */

// 用户组查询
const queryUserGroup = data => {
  return service({
    url: '/base/userGroup/queryUserGroup',
    method: 'post',
    data
  })
}

// 用户组新增
const addUserGroup = data => {
  return service({
    url: '/base/userGroup/add',
    method: 'post',
    data
  })
}

// 修改用户组
const updateUserGroup = data => {
  return service({
    url: '/base/userGroup/update',
    method: 'put',
    data
  })
}

// 启用禁用 用户组
const updateUserGroupStatus = data => {
  return service({
    url: `/base/userGroup/updateStatus`,
    method: 'get',
    data
  })
}

// 获取所有用户组
const queryAllUserGroup = data => {
  return service({
    url: `/base/userGroup/queryAllUserGroup`,
    method: 'get',
    data
  })
}

export {
  queryUserGroup,
  addUserGroup,
  updateUserGroup,
  updateUserGroupStatus,
  queryAllUserGroup
}

/* ----------------- 用户组模块 ---------------------- */

/* ----------------- 角色模块 ---------------------- */

// 新增角色
const insertRole = data => {
  return service({
    url: '/base/role/add',
    method: 'post',
    data
  })
}

// 修改角色
const updateRole = data => {
  return service({
    url: '/base/role/update',
    method: 'put',
    data
  })
}

// 启用|禁用 角色
const updateStatusRole = data => {
  return service({
    url: '/base/role/updateStatus',
    method: 'get',
    data
  })
}

// 查询角色列表
const queryRoleList = data => {
  return service({
    url: '/base/role/queryRole',
    method: 'post',
    data
  })
}



// 获取角色名列表(查询所有角色)
const getRoleLabelList = data => {
  return service({
    url: '/base/role/queryAllRole',
    method: 'get',
    data
  })
}
//获取角色名列表(查询业务公司下所有公司对应角色)
const queryChooseRoles = data => {
  return service({
    url: '/base/role/queryChooseRoles',
    method: 'post',
    data
  })
}


// 获取系统列表
const getMenuLabelList = data => {
  return service({
    url: '/base/menu/getByParentId',
    method: 'get',
    data
  })
}


// 获取权限管理列表
const getAuthorizationMenu = data => {
  return service({
    url: '/base/role/queryByRoleId',
    method: 'get',
    data
  })
}

// 更新权限接口
const updateAuthorizationMenu = data => {
  return service({
    url: '/base/role/updateRoleMenu',
    method: 'put',
    data
  })
}
//新增角色可选系统-new
const queryChooseSystems = data => {
  return service({
    url: '/base/role/queryChooseSystems',
    method: 'get',
    data
  })
}



export {
  queryRoleList,
  insertRole,
  updateRole,
  updateStatusRole,
  getRoleLabelList,
  getMenuLabelList,
  getAuthorizationMenu,
  updateAuthorizationMenu,
  queryChooseRoles,
  queryChooseSystems
}

/* ----------------- 角色模块 ---------------------- */

/* ----------------- 企业模块 ---------------------- */

// 查询企业树（企业树）
const getBusinessCompanyTree = data => {
  return service({
    url: `${BASE_PREFIX}/company/getBusinessCompanyTree`,
    method: 'get',
    data
  })
}


// 查询企业树（权限树--角色|企业|用户）
const getAuthCompanyTree = data => {
  return service({
    url: `${BASE_PREFIX}/company/getCompanyTree`,
    method: 'get',
    data
  })
}



// 获取公司名列表
const getCompanyLabels = data => {
  return service({
    url: `${BASE_PREFIX}/company/getCompanyLabelByUserId`,
    method: 'get',
    data
  })
}

// 新增企业
const insertCompany = data => {
  return service({
    url: `${BASE_PREFIX}/company/add`,
    method: 'post',
    data
  })
}

// 获取企业详情
const getCompanyDetailLabels = data => {
  return service({
    url: `${BASE_PREFIX}/company/getCompanyById`,
    method: 'get',
    data
  })
}
//财来财往-企业微信配置
const getWxConfigList = data => {
  return service({
    url: `/fortune/wx/config/queryWxConfigList`,
    method: 'post',
    data
  })
}
// 删除企业
const deleteCompany = data => {
  return service({
    url: `${BASE_PREFIX}/company/delete/${data}`,
    method: 'delete'
  })
}

// 修改企业
const updateCompany = data => {
  return service({
    url: `${BASE_PREFIX}/company/update`,
    method: 'put',
    data
  })
}


//获取车队列表
const queryImportCompanyGroups = data => {
  return service({
    url: `${BASE_PREFIX}/company/queryImportCompanyGroups`,
    method: 'get',
    data
  })
}

export {
  getBusinessCompanyTree,
  getCompanyLabels,
  insertCompany,
  getCompanyDetailLabels,
  deleteCompany,
  getAuthCompanyTree,
  updateCompany,
  queryImportCompanyGroups,
  getWxConfigList,//财来财往-
}

/* ----------------- 企业模块 ---------------------- */

/* ----------------- 车辆模块 ---------------------- */

// 查询车辆列表
const getVehicleList = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/queryList`,
    method: 'post',
    data
  })
}

// 通过id查询车辆列表
const getVehicleById = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/load?id=${data}`,
    method: 'get'
  })
}

//通过终端号获取终端信息
const getInfoByDeviceNo = data => {
  return service({
    url: `${BASE_PREFIX}/device/queryByDeviceNo?deviceNo=${data}`,
    method: 'get'
  })
}

// 新增车辆
const insertVehicle = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/insert`,
    method: 'post',
    data
  })
}

// 修改车辆
const updateVehicle = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/update`,
    method: 'put',
    data
  })
}

// 车牌号模糊查询
const queryVehicleByCph = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/queryVehicleByCph`,
    method: 'get',
    data
  })
}

// 通过线路查询车辆
const queryVehicleByLine = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/getAllVehicle`,
    method: 'post',
    data
  })
}

// 车辆附加信息查询
const getVehicleInfo = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/getVehicleInfo`,
    method: 'get',
    data
  })
}
//车辆详情
const queryVehicleDetailInfo = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/info`,
    method: 'get',
    data
  })
}

// 查询省市接口
const queryAreaList = data => {
  return service({
    url: `${BASE_PREFIX}/area/list`,
    method: 'get',
    data
  })
}

// 更新车辆状态
const updateVehicleStatus = (data) => {
  return service({
    url: '/base/vehicle/update/status',
    method: 'put',
    data
  });
}
//查询车辆行驶证、保险过期信息
const queryVehicleCertificateExpireDetail = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/getVehicleCertificateExpireDetail`,
    method: 'get',
    data
  })
}
//20230425 谢 过期提醒列表
const queryVehicleCertificateExpire = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/queryExpiring`,
    method: 'get',
    data
  })
}
const queryDriverExpire = data => {
  return service({
    url: `${BASE_PREFIX}/driver/queryExpiring`,
    method: 'get',
    data
  })
}

export {
  getVehicleList,
  getVehicleById,
  getInfoByDeviceNo,
  insertVehicle,
  updateVehicle,
  queryVehicleByCph,
  queryVehicleByLine,
  getVehicleInfo,
  queryVehicleDetailInfo,
  updateVehicleStatus,
  queryAreaList,
  queryVehicleCertificateExpireDetail,
  queryVehicleCertificateExpire,
  queryDriverExpire
}

/* ----------------- 车辆模块 ---------------------- */

/* ----------------- 驾驶员模块 ---------------------- */

// 查询驾驶员列表
const getDriverList = data => {
  return service({
    url: '/base/driver/queryDriverByQuery',
    method: 'post',
    data
  })
}

// 新增驾驶员
const insertDriver = data => {
  return service({
    url: '/base/driver/insert',
    method: 'post',
    data
  })
}

// 修改驾驶员
const updateDriver = data => {
  return service({
    url: '/base/driver/updateById',
    method: 'put',
    data
  })
}

// 查询详情列表数据
const getDriverDetail = data => {
  return service({
    url: '/base/driver/getDriverById',
    method: 'get',
    data
  })
}

// 启用禁用驾驶员
const updateDriverStatus = data => {
  return service({
    url: '/base/driver/updateDriverStatus',
    method: 'put',
    data
  })
}



// 驾驶员名称模糊查询
const queryDriverByName = data => {
  return service({
    url: '/base/monitor/driver',
    method: 'get',
    data
  })
}

// 获取驾驶员下拉框
const getDeiverNameList = data => {
  return service({
    url: '/base/driver/queryDriverNameList',
    method: 'get',
    data
  })
}

export {
  getDriverList,
  insertDriver,
  updateDriver,
  getDriverDetail,
  updateDriverStatus,
  queryDriverByName,
  getDeiverNameList
}

/* ----------------- 驾驶员模块 ---------------------- */

/* ----------------- 终端模块 ---------------------- */

// 查询终端列表
const getDeviceList = data => {
  return service({
    url: `${BASE_PREFIX}/device/queryList`,
    method: 'post',
    data
  })
}

// 新增终端
const insertDevice = data => {
  return service({
    url: `${BASE_PREFIX}/device/insert`,
    method: 'post',
    data
  })
}


// 修改终端
const updateDevice = data => {
  return service({
    url: `${BASE_PREFIX}/device/update`,
    method: 'put',
    data
  })
}

// 删除终端
const deleteDevice = data => {
  return service({
    url: `${BASE_PREFIX}/device/delete/${data}`,
    method: 'delete'
  })
}
//修改终端状态
const updateDeviceStatus = data => {
  return service({
    url: `${BASE_PREFIX}/device/updateStatus`,
    method: 'get',
    data
  })
}
// 根据车架号查询终端列表
const queryByVehicleNo = data => {
  return service({
    url: `${BASE_PREFIX}/device/queryByVehicleNo`,
    method: 'get',
    data
  })
}

// 设备信息查询 - 通过设备号（添加设备使用）
const queryByDeviceNo = data => {
  return service({
    url: `${BASE_PREFIX}/device/getByDeviceNo`,
    method: 'get',
    data
  })
}

//2023/12/7 终端属性
// 终端属性分页
const queryDeviceInspectPage = data => {
  return service({
    url: `/report/device/inspect/queryDeviceInspectPage`,
    method: 'post',
    data
  })
}
//终端属性详情
const queryDeviceInspectInfo = data => {
  return service({
    url: `/report/device/inspect/queryDeviceInspectInfo`,
    method: 'get',
    data
  })
}

export {
  getDeviceList,
  insertDevice,
  updateDevice,
  deleteDevice,
  updateDeviceStatus,
  queryByVehicleNo,
  queryByDeviceNo,
  queryDeviceInspectPage,
  queryDeviceInspectInfo
}

/* ----------------- 终端模块 ---------------------- */

/* ----------------- IC卡模块 ---------------------- */

// 查询IC卡列表
const getCardList = data => {
  return service({
    url: `${BASE_PREFIX}/ic/queryList`,
    method: 'get',
    data
  })
}

// 新增IC卡
const insertCard = data => {
  return service({
    url: `${BASE_PREFIX}/ic/insert`,
    method: 'post',
    data
  })
}


// 修改IC卡
const updateCard = data => {
  return service({
    url: `${BASE_PREFIX}/ic/update`,
    method: 'put',
    data
  })
}

// IC卡遗失
const cardLoss = data => {
  return service({
    url: `${BASE_PREFIX}/ic/cardLoss`,
    method: 'put',
    data
  })
}

// IC卡制卡
const makeCard = data => {
  return service({
    url: `${BASE_PREFIX}/ic/makeCard`,
    method: 'put',
    data
  })
}

export {
  getCardList,
  insertCard,
  updateCard,
  cardLoss,
  makeCard
}

/* ----------------- IC卡模块 ---------------------- */

/* ----------------- 数据字典模块 ---------------------- */


// 新增字典
const addDictionary = data => {
  return service({
    url: `${BASE_PREFIX}/dict/insert`,
    method: 'post',
    data
  })

}
// 修改字典
const editDictionary = data => {
  return service({
    url: `${BASE_PREFIX}/dict/updateDictValue`,
    method: 'put',
    data
  })
}
// 查询-字典列表
const queryDictionaryList = data => {
  return service({
    url: `${BASE_PREFIX}/dict/queryDict`,
    method: 'get',
    data
  })
}

// 查询-字典值列表
const queryDictionaryValueList = data => {
  return service({
    url: `${BASE_PREFIX}/dict/queryDictValue`,
    method: 'get',
    data
  })
}

// 更新-禁用/启用
const updateDictionaryDisable = data => {
  return service({
    url: `${BASE_PREFIX}/dict/updateDisable`,
    method: 'put',
    data
  })
}


// 查询所有（字典 字典值）
const queryDictByParentCode = data => {
  return service({
    url: `${BASE_PREFIX}/dict/queryByParentCode`,
    method: 'get',
    data
  })
}

// 查询所有（字典 字典值）-- 通过父类集合
const queryDictsByCodes = data => {
  return service({
    url: `${BASE_PREFIX}/dict/queryByParentCodeS`,
    method: 'get',
    data
  })
}
//奖惩培训记录-编辑
const insertDriverOtherEdit = data => {
  return service({
    url: `${BASE_PREFIX}/driver/insertDriverOther`,
    method: 'post',
    data
  })
}
//奖惩培训记录-详情
const getDriverOtherDetails = data => {
  return service({
    url: `${BASE_PREFIX}/driver/getDriverOther/${data}`,
    method: 'get',
  })
}

export {
  queryDictionaryList,
  addDictionary,
  editDictionary,
  queryDictionaryValueList,
  updateDictionaryDisable,
  queryDictByParentCode,
  queryDictsByCodes,
  insertDriverOtherEdit,
  getDriverOtherDetails
}

/* ----------------- 数据字典模块 ---------------------- */
/* ----------------- 终端类型管理模块 ---------------------- */
// 新增终端类型
const addDeviceModel = data => {
  return service({
    url: `${BASE_PREFIX}/deviceModel/insert`,
    method: 'post',
    data
  })

}
// 修改终端类型
const editDeviceModel = data => {
  return service({
    url: `${BASE_PREFIX}/deviceModel/update`,
    method: 'put',
    data
  })
}
// 查询-终端类型列表
const queryDeviceModelList = data => {
  return service({
    url: `${BASE_PREFIX}/deviceModel/queryList`,
    method: 'get',
    data
  })
}

// 查询所有（终端类型）
const queryAllDeviceModel = data => {
  return service({
    url: `${BASE_PREFIX}/deviceModel/queryAllList`,
    method: 'get',
    data
  })
}
export {
  addDeviceModel,
  editDeviceModel,
  queryDeviceModelList,
  queryAllDeviceModel
}

/* ----------------- 终端类型管理模块 ---------------------- */

/* ----------------- 终端类型管理模块 ---------------------- */
// 新增终端类型
const addDeviceUpgrade = data => {
  return service({
    url: `${BASE_PREFIX}/deviceUpgrade/insert`,
    method: 'post',
    data
  })

}

// 查询-终端类型列表
const queryDeviceUpgradeList = data => {
  return service({
    url: `${BASE_PREFIX}/deviceUpgrade/query`,
    method: 'post',
    data
  })
}

export {
  addDeviceUpgrade,
  queryDeviceUpgradeList,
}

/* ----------------- 终端类型管理模块 ---------------------- */

/* ----------------- 操作日志查询模块 ---------------------- */

// 日志分页查询
const queryUserOperationLog = data => {
  return service({
    url: `${BASE_PREFIX}/userOperationLog/queryUserOperationLogByQuery`,
    method: 'post',
    data
  })
}

// 获取操作目录
const getAllCatalogue = data => {
  return service({
    url: `${BASE_PREFIX}/userOperationLog/getAllCatalogue`,
    method: 'get',
    data
  })
}
//获取操作方式
const getAllOperation = data => {
  return service({
    url: `${BASE_PREFIX}/userOperationLog/getAllReqType`,
    method: 'get',
    data
  })
}
// 根据id获取明细
const getUserOperationLogById = data => {
  return service({
    url: `${BASE_PREFIX}/userOperationLog/getUserOperationLogById`,
    method: 'get',
    data
  })
}

// 用户登录登出日志
const queryUserLoginHistory = data => {
  return service({
    url: `${BASE_PREFIX}/user/queryUserLoginHistory`,
    method: 'post',
    data
  })
}
//用户操作统计
const queryUserFrequencyOfOperation = data => {
  return service({
    url: `report/manageStatistics/queryUserFrequencyOfOperation`,
    method: 'post',
    data
  })
}
//查询数据历史操作记录
const queryOperationPageByMenu = data => {
  return service({
    url: `${BASE_PREFIX}/userOperationLog/queryOperationPageByMenu`,
    method: 'post',
    data
  })
}

//异常终端日志列表
const queryAbnormalDeviceLogPage = data => {
  return service({
    url: `${BASE_PREFIX}/abnormalDeviceLog/queryPage`,
    method: 'post',
    data
  })
}
//获取判定全局配置
const queryAbnormalDeviceLogConfig = data => {
  return service({
    url: `${BASE_PREFIX}/abnormalDeviceLog/getConfig`,
    method: 'get',
    data
  })
}
//设置判定全局配置
const saveAbnormalDeviceLogConfig = data => {
  return service({
    url: `${BASE_PREFIX}/abnormalDeviceLog/saveConfig`,
    method: 'post',
    data
  })
}

export {
  queryUserOperationLog,
  getAllCatalogue,
  getAllOperation,
  getUserOperationLogById,
  queryUserLoginHistory,
  queryUserFrequencyOfOperation,
  queryOperationPageByMenu,
  queryAbnormalDeviceLogPage,
  queryAbnormalDeviceLogConfig,
  saveAbnormalDeviceLogConfig
}

/* ----------------- 操作日志查询模块 ---------------------- */
/* ----------------- 准入管理模块 ---------------------- */
// 新增准入管理
const addCompanyDevice = data => {
  return service({
    url: `${BASE_PREFIX}/companyDevice/insert`,
    method: 'post',
    data
  })

}
// 修改准入管理
const editCompanyDevice = data => {
  return service({
    url: `${BASE_PREFIX}/companyDevice/updateById`,
    method: 'put',
    data
  })
}

//删除准入管理-根据配置id
const deleteCompanyDevice = data => {
  return service({
    url: `${BASE_PREFIX}/companyDevice/deleteById/${data}/`,
    method: 'delete'
  })
}


// 查询-准入管理列表
const queryCompanyDeviceList = data => {
  return service({
    url: `${BASE_PREFIX}/companyDevice/queryCompanyDeviceByQuery`,
    method: 'post',
    data
  })
}

// 根据公司id查询准入配置
const getCompanyDeviceByCompanyId = data => {
  return service({
    url: `${BASE_PREFIX}/companyDevice/getCompanyDeviceByCompanyId`,
    method: 'get',
    data
  })
}

// 根据企业id删除准入配置
const deleteByCompanyId = data => {
  return service({
    url: `${BASE_PREFIX}/companyDevice/deleteByCompanyId`,
    method: 'get',
    data
  })
}
export {
  addCompanyDevice,
  editCompanyDevice,
  getCompanyDeviceByCompanyId,
  deleteCompanyDevice,
  queryCompanyDeviceList,
  deleteByCompanyId
}

/* ----------------- 准入管理模块 ---------------------- */

/* -----------------  消息文本模块 ---------------------- */
// 新增消息文本
const addMessageTemplate = data => {
  return service({
    url: `${BASE_PREFIX}/messageTemplate/add`,
    method: 'post',
    data
  })

}
// 修改消息文本
const editMessageTemplate = data => {
  return service({
    url: `${BASE_PREFIX}/messageTemplate/update`,
    method: 'put',
    data
  })
}
//删除消息文本
const deleteMessageTemplate = data => {
  return service({
    url: `${BASE_PREFIX}/messageTemplate/deleteById`,
    method: 'delete',
    data
  })
}
// 查询-消息文本列表
const queryMessageTemplateList = data => {
  return service({
    url: `${BASE_PREFIX}/messageTemplate/queryAll`,
    method: 'get',
    data
  })
}
//报警前后几分钟定位轨迹
const alarmTraceList = data => {
  return service({
    url: `${BASE_PREFIX}/alarm/alarmTrace`,
    method: 'get',
    data
  })
}
export {
  addMessageTemplate,
  editMessageTemplate,
  deleteMessageTemplate,
  queryMessageTemplateList,
  alarmTraceList
}

/* ----------------- 消息文本模块 ---------------------- */

/* ----------------- 警报管理模块 ---------------------- */
// 查询警报类型列表
const getAlarmNameList = data => {
  return service({
    url: '/base/alarmConfiguration/queryAlarmNameList',
    method: 'get',
    data
  })
}
//查询未处理的报警列表
const queryNoDisposeAlarm = data => {
  return service({
    url: '/base/alarm/queryNoDisposeAlarm',
    method: 'post',
    data
  })
}
// 查询警报配置列表
const getAlarmConfigs = data => {
  return service({
    url: '/base/alarmConfiguration/getAlarmConfigurationDetailList',
    method: 'post',
    data
  })
}

// 修改报警管理配置
const updateAlarmConfigs = data => {
  return service({
    url: '/base/alarmConfiguration/updateAlarmConfigurationDetailList',
    method: 'post',
    data
  })
}

// 查询报警信息列表数据
const queryAlarmList = data => {
  return service({
    url: '/base/alarm/alarmDataList',
    method: 'post',
    data
  })
}
// 查询报警信息列表数据------v3.0版本更换列表展示方式
const queryAlarmListV2 = data => {
  return service({
    url: '/base/alarm/alarmDataList/v2',
    method: 'post',
    data
  })
}
//查询报警明细
const queryAlarmDetailByQuery = data => {
  return service({
    url: '/base/alarm/queryAlarmDetailByQuery',
    method: 'post',
    data
  })
}

// 查询报警信息警报详情
const queryAlarmDetail = data => {
  return service({
    url: '/base/alarm/queryAlarmDetail',
    method: 'get',
    data
  })
}

// 查询报警附件
const queryAlarmFile = data => {
  return service({
    url: '/base/alarm/queryAlarmFile',
    method: 'get',
    data
  })
}

// 查询报警信息报警数量统计
const queryAlarmCount = data => {
  return service({
    url: '/base/alarm/alarmDataCount',
    method: 'get',
    data
  })
}

// 报警信息手动处理
const handleDisposeAlarm = data => {
  return service({
    url: '/base/alarm/disposeAlarm',
    method: 'post',
    data
  })
}
//油量查询 
const queryOilConsumeCount = data => {
  return service({
    url: '/report/oilConsumeCount/query',
    method: 'post',
    data
  })
}

export {
  getAlarmNameList,
  getAlarmConfigs,
  updateAlarmConfigs,
  queryAlarmList,
  queryAlarmListV2,
  queryAlarmDetailByQuery,
  queryAlarmDetail,
  queryAlarmFile,
  queryAlarmCount,
  handleDisposeAlarm,
  queryNoDisposeAlarm,
  queryOilConsumeCount
}
/* ----------------- 警报管理模块 ---------------------- */

/* -----------------  升级包管理模块 ---------------------- */
// 新增升级包
const addUpgrade = data => {
  return service({
    url: `${BASE_PREFIX}/devUpgrade/add`,
    method: 'post',
    data
  })

}

// 查询-升级包列表
const queryUpgradeList = data => {
  return service({
    url: `${BASE_PREFIX}/devUpgrade/packages`,
    method: 'post',
    data
  })
}

// 删除升级包
const delUpgrade = data => {
  return service({
    url: `${BASE_PREFIX}/devUpgrade/delete/${data}`,
    method: 'delete'
  })
}

// 查询FTP服务器参数
const queryFtpAccount = data => {
  return service({
    url: `${BASE_PREFIX}/devUpgrade/ftpAccount`,
    method: 'get',
    data
  })
}
//视频服务日志
const saveVideoLog = data => {
  return service({
    url: `${BASE_PREFIX}/video/log/saveVideoLog`,
    method: 'post',
    data
  })
}
//视频服务报警
const clientVideoError = data => {
  return service({
    url: `${BASE_PREFIX}/video/log/clientError`,
    method: 'post',
    data
  })
}

export {
  addUpgrade,
  queryUpgradeList,
  delUpgrade,
  queryFtpAccount,
  saveVideoLog,
  clientVideoError
}
/* -----------------  升级包管理模块 ---------------------- */
/* -----------------  下发指令模块 ---------------------- */
// 查询终端参数
const queryDeviceParams = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/deviceParams/${data}`,
    method: 'get'
  })
}

// 设置终端参数
const setDeviceParams = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/deviceParams`,
    method: 'post',
    data
  })
}

// 查询驾驶员信息
const queryDriver = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/driver/${data}`,
    method: 'get'
  })
}

// 车辆管理-设置营运线路
const updateVehicleLine = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/updateInfo`,
    method: 'put',
    data
  })
}
// IC卡写卡参数
const makeCardParams = data => {
  return service({
    url: `${BASE_PREFIX}/ic/makeCardParams`,
    method: 'get',
    data
  })
}

// 根据车架号获取禁行配置
const queryProhibition = data => {
  return service({
    url: `${BASE_PREFIX}/prohibitionConfig/queryByVehicleId`,
    method: 'get',
    data
  })
}
// 禁行配置保存
const saveProhibition = data => {
  return service({
    url: `${BASE_PREFIX}/prohibitionConfig/save`,
    method: 'post',
    data
  })
}
//区域围栏报警设置
const getVehicleConfig = data => {
  return service({
    url: `${BASE_PREFIX}/enclosure/getVehicleConfig`,
    method: 'get',
    data
  })
}
//保存所选区域围栏报警配置
const updateVehicleConfig = data => {
  return service({
    url: `${BASE_PREFIX}/enclosure/updateVehicleConfig`,
    method: 'post',
    data
  })
}
export {
  queryDeviceParams,
  setDeviceParams,
  queryDriver,
  updateVehicleLine,
  makeCardParams,
  queryProhibition,
  saveProhibition,
  getVehicleConfig,
  updateVehicleConfig
}
/* -----------------  下发指令模块 ---------------------- */

/* -----------------  运维管理模块 ---------------------- */
// 查询表名或表字段接口
const querDbStructure = data => {
  return service({
    url: `${BASE_PREFIX}/ops/dbStructure`,
    method: 'get',
    data
  })
}
// 查询表数据接口
const querDbData = data => {
  return service({
    url: `${BASE_PREFIX}/ops/data`,
    method: 'get',
    data
  })
}
export {
  querDbStructure,
  querDbData,
}
/* -----------------  运维管理模块 ---------------------- */

/* -----------------  消息推送模块 ---------------------- */
// 用户消息推送接口
const sendMessage = data => {
  return service({
    url: `${BASE_PREFIX}/message/send`,
    method: 'post',
    data
  })
}

export {
  sendMessage
}
/* -----------------  消息推送模块 ---------------------- */

/* -----------------  四维图新 ---------------------- */
//获取验证码图片
const getCaptcha = data => {
  return service({
    url: `${BASE_PREFIX}/roadMap/getCaptcha`,
    method: 'get',
    data
  })
}
//获取外部网站token
const loginRoadMap = data => {
  return service({
    url: `${BASE_PREFIX}/roadMap/loginRoadMap`,
    method: 'get',
    data
  })
}
//获取终端状态详情
const findMapsByDeviceNo = data => {
  return service({
    url: `${BASE_PREFIX}/roadMap/findMapsByDeviceNo`,
    method: 'get',
    data
  })
}
//终端日志查询
const queryUpdateLog = data => {
  return service({
    url: `${BASE_PREFIX}/roadMap/queryUpdateLog`,
    method: 'get',
    data
  })
}
//2020/12/20修改新版路网状态图2.0-获取详情
const getRoadVehicleDetail = data => {
  return service({
    url: `${BASE_PREFIX}/roadMapUpgrade/getRoadVehicleDetail`,
    method: 'get',
    data
  })
}
//路网图2.0查看版本
const getVersionList = data => {
  return service({
    url: `${BASE_PREFIX}/roadMapUpgrade/getVersionList`,
    method: 'get',
    data
  })
}

export {
  getCaptcha,
  loginRoadMap,
  findMapsByDeviceNo,
  getRoadVehicleDetail,
  getVersionList,
  queryUpdateLog
}
/* -----------------  四维图新 ---------------------- */

/*------------------  数据转发 ---------------------*/

//平台列表查询
const queryplatformList = data =>{
  return service({
    url: `/dataforward/platform/manage/queryList`,
    method: 'post',
    data
  })
}
//平台保存
const savePlatform = data =>{
  return service({
    url: `/dataforward/platform/manage/save`,
    method: 'post',
    data
  })
}
//更新状态
const platformUpdateStatus = data =>{
  return service({
    url: `/dataforward/platform/manage/updateStatus`,
    method: 'put',
    data
  })
}
//查询订阅数据列表
const querySubscribeList = data =>{
  return service({
    url: `/dataforward/platform/manage/querySubscribeList`,
    method: 'get',
    data
  })
}
//保存平台订阅数据关系
const saveSubscribe = data =>{
  return service({
    url: `/dataforward/platform/manage/saveSubscribe`,
    method: 'post',
    data
  })
}
//数据转发服务平台绑定线路列表
const queryBindLineList = data =>{
  return service({
    url: `/dataforward/platform/manage/queryBindLineList`,
    method: 'get',
    data
  })
}
//转发平台绑定线路
const saveBindLine = data =>{
  return service({
    url: `/dataforward/platform/manage/saveBindLine`,
    method: 'post',
    data
  })
}
//查询数据类别列表-分页
const queryDataTypeList = data =>{
  return service({
    url: `/dataforward/datacategory/manage/queryListByPage`,
    method: 'post',
    data
  })
}
//数据类别保存
const saveDataType = data =>{
  return service({
    url: `/dataforward/datacategory/manage/save`,
    method: 'post',
    data
  })
}

export {
  queryplatformList,
  savePlatform,
  platformUpdateStatus,
  querySubscribeList,
  saveSubscribe,
  queryDataTypeList,
  saveDataType,
  queryBindLineList,
  saveBindLine,
}

/*------------------  数据转发 ---------------------*/
