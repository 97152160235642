const dev = {
    "apiBaseUrl": "http://192.168.0.158:12908/",
    //环卫测试环境https https://its.test.inheritech.top:10443/api/
    "websocketBaseUrl": "ws://192.168.0.173:13001/",
    "videoIframeUrl": "http://39.104.179.71:4022/flv?port=10077&app=live&stream=",
    "videoBaseUrl": "39.104.179.71",
    "videoPortList": {
        "4022": "9500",
        "4023": "9501",
        "4024": "9502",
        "4025": "9503"
    },
    "videoIframeUrlList": {
        "4022": "http://39.104.179.71:4022/flv?port=10077&app=live&stream=",
        "4023": "http://39.104.179.71:4023/flv?port=10078&app=live&stream=",
        "4024": "http://39.104.179.71:4024/flv?port=10079&app=live&stream=",
        "4025": "http://39.104.179.71:4025/flv?port=10080&app=live&stream="
    },
    "videoIframeUrl_wsFlv": "ws://39.104.179.71:4025/ws?port=10080&app=live&stream=",//视频地址前缀，后面拼终端号+通道号-----9213
    "videoPortList_wsFlv":"9505",//下发指令port-9505  
    "videoHistoryPort": "9605",//9600-http-flv
    "videoHistoryPort": "9600",
    "taxiVideoIframeUrl": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream=",
    "taxiVideoPortList": {
        "4022": "9503"
    },
    "taxiVideoIframeUrlList": {
        "4022": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream="
    },
    "taxiVideoHistoryPort": "9603",
    "tese": "47.99.134.236:16803//39.104.71.7:12901//192.168.0.163:8082/gps/|| 192.168.0.158:12908  39.104.179.71",
    "baseChartsUrl": "http://dev.ark.inheritech.cn",
    "AssessPageBaseUrl": ":12911",
    "BillPageBaseUrl":"3000",
    "OperatePageBaseUrl": ":12910",
    "historyBaseUrlVideo": "https://test.bus.inheritech.top/livehis/video/",
    "webPortBaseUrlVideo": "https://test.bus.inheritech.top",
    "realTimeBaseUrlVideo": {
        "6001": "https://test.bus.inheritech.top/live/video/"
    },
    "realTimeBaseUrl": "https://test.bus.inheritech.top/live/video/",
    "realTimevideoPort": {
        "6001": "17015"
    },
    "historyPortVideo": "17019",
    "basePortIp": "183.223.222.119",
    "ListBusinessIp": "http://192.168.0.188:8418"
}
//window
const pro = {
    // "apiBaseUrl": "https://its.api.inheritech.top/",
    // "websocketBaseUrl": "wss://its.inheritech.top/wss/",
    "apiBaseUrl": "http://its.api.inheritech.top/",
    "websocketBaseUrl": "ws://116.62.115.153:8047/",
    "videoIframeUrl": "http://39.104.179.71:4022/flv?port=10077&app=live&stream=",
    "videoBaseUrl": "39.104.179.71",//39.104.16.24
    "videoPortList": {
        "4022": "9500",
        "4023": "9501",
        "4024": "9502",
        "4025": "9503"
    },
    "videoIframeUrlList": {
        "4022": "http://39.104.179.71:4022/flv?port=10077&app=live&stream=",
        "4023": "http://39.104.179.71:4023/flv?port=10078&app=live&stream=",
        "4024": "http://39.104.179.71:4024/flv?port=10079&app=live&stream=",
        "4025": "http://39.104.179.71:4025/flv?port=10080&app=live&stream="
    },
    /**
     * ws-flv
     */
    "videoIframeUrl_wsFlv": "ws://39.104.179.71:4025/ws?port=10080&app=live&stream=",//视频地址前缀，后面拼终端号+通道号-----9213
    
    //对应的；拉流入口：9505 历史入流 9605 nginx-windows
    //ws://39.104.179.71:4025/ws?port=10080&app=live&stream=,
    //对应的：拉流入口：9500 历史入流：9600  linux 
    //ws://39.104.16.24:4025/ws?port=10077&app=live&stream= ，
    "videoPortList_wsFlv":"9505",//下发指令port-9505  
    "videoHistoryPort": "9605",//9600-http-flv
    "taxiVideoIframeUrl": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream=",
    "taxiVideoPortList": {
        "4022": "9503"
    },
    "taxiVideoIframeUrlList": {
        "4022": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream="
    },
    "taxiVideoHistoryPort": "9603",
    "tese": "47.99.134.236:16803//39.104.71.7:12901//192.168.0.163:8082/gps/|| 192.168.0.158:12908  39.104.179.71",
    "baseChartsUrl": "http://pro.ark.inheritech.top",
    "AssessPageBaseUrl": "/jxkh",
    "OperatePageBaseUrl": "/jyfx",
    "historyBaseUrlVideo": "https://video.inheritech.top/livehis/video/",
    "webPortBaseUrlVideo": "https://video.inheritech.top",
    "realTimeBaseUrlVideo": {
        "6001": "wss://newvideo.inheritech.top:4026/ws?port=10077&app=live&stream="
        // "6001": "https://video.inheritech.top/live/video/"
    },
    "realTimeBaseUrl": "wss://newvideo.inheritech.top:4026/ws?port=10077&app=live&stream=",
    "realTimevideoPort": {
        "6001": "9500"
        // "6001": "17015"
    },
    "videoBasePort":"9500",
    "historyPortVideo": "17019",
    // "basePortIp": "39.104.179.71",
    "basePortIp": "116.62.247.103",
    "ListBusinessIp": "http://192.168.0.188:8418"
}
const prolim = {
    // "apiBaseUrl": "https://its.api.inheritech.top/",
    // "websocketBaseUrl": "wss://its.inheritech.top/wss/",
    "apiBaseUrl": "http://its.api.inheritech.top/",
    "websocketBaseUrl": "ws://116.62.115.153:8047/",
    "videoIframeUrl": "http://39.104.179.71:4022/flv?port=10077&app=live&stream=",
    "videoBaseUrl": "39.104.16.24",//39.104.16.24
    "videoPortList": {
        "4022": "9500",
        "4023": "9501",
        "4024": "9502",
        "4025": "9503"
    },
    "videoIframeUrlList": {
        "4022": "http://39.104.179.71:4022/flv?port=10077&app=live&stream=",
        "4023": "http://39.104.179.71:4023/flv?port=10078&app=live&stream=",
        "4024": "http://39.104.179.71:4024/flv?port=10079&app=live&stream=",
        "4025": "http://39.104.179.71:4025/flv?port=10080&app=live&stream="
    },
    /**
     * ws-flv
     */
    "videoIframeUrl_wsFlv": "ws://39.104.16.24:4025/ws?port=10077&app=live&stream=",//视频地址前缀，后面拼终端号+通道号-----9213
    
    //对应的；拉流入口：9505 历史入流 9605 nginx-windows
    //ws://39.104.179.71:4025/ws?port=10080&app=live&stream=,
    //对应的：拉流入口：9500 历史入流：9600  linux 
    //ws://39.104.16.24:4025/ws?port=10077&app=live&stream= ，
    "videoPortList_wsFlv":"9500",//下发指令port-9505  
    "videoHistoryPort": "9600",//9600-http-flv
    "taxiVideoIframeUrl": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream=",
    "taxiVideoPortList": {
        "4022": "9503"
    },
    "taxiVideoIframeUrlList": {
        "4022": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream="
    },
    "taxiVideoHistoryPort": "9603",
    "tese": "47.99.134.236:16803//39.104.71.7:12901//192.168.0.163:8082/gps/|| 192.168.0.158:12908  39.104.179.71",
    "baseChartsUrl": "http://pro.ark.inheritech.top",
    "AssessPageBaseUrl": "/jxkh",
    "OperatePageBaseUrl": "/jyfx",
    "historyBaseUrlVideo": "https://video.inheritech.top/livehis/video/",
    "webPortBaseUrlVideo": "https://video.inheritech.top",
    "realTimeBaseUrlVideo": {
        "6001": "wss://newvideo.inheritech.top:4026/ws?port=10077&app=live&stream="
        // "6001": "https://video.inheritech.top/live/video/"
    },
    "realTimeBaseUrl": "wss://newvideo.inheritech.top:4026/ws?port=10077&app=live&stream=",
    "realTimevideoPort": {
        "6001": "9500"
        // "6001": "17015"
    },
    "videoBasePort":"9500",
    "historyPortVideo": "17019",
    // "basePortIp": "39.104.179.71",
    "basePortIp": "116.62.247.103",
    "ListBusinessIp": "http://192.168.0.188:8418"
}
const devHttps = {
    "apiBaseUrl": "https://its.test.inheritech.top:10443/api/",
    "websocketBaseUrl": "wss://its.test.inheritech.top:10443/wss/",
}
const https = {
    "apiBaseUrl": "https://its.api.inheritech.top/",
    "websocketBaseUrl": "wss://its.inheritech.top/wss/",
    "videoIframeUrl": "http://39.104.179.71:4022/flv?port=10077&app=live&stream=",
    "videoBaseUrl": "39.104.179.71",
    "videoPortList": {
        "4022": "9503",
        "4023": "9503",
        "4024": "9503",
        "4025": "9503"
    },
    "videoIframeUrlList": {
        "4022": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream=",
        "4023": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream=",
        "4024": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream=",
        "4025": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream="
    },
    "videoHistoryPort": "9603",
    "taxiVideoIframeUrl": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream=",
    "taxiVideoPortList": {
        "4022": "9503"
    },
    "taxiVideoIframeUrlList": {
        "4022": "wss://video.inheritech.top:4026/ws?port=10080&app=live&stream="
    },
    "taxiVideoHistoryPort": "9603",
    "tese": "47.99.134.236:16803//39.104.71.7:12901//192.168.0.163:8082/gps/|| 192.168.0.158:12908  39.104.179.71",
    "baseChartsUrl": "http://pro.ark.inheritech.top",
    "AssessPageBaseUrl": "/jxkh",
    "OperatePageBaseUrl": "/jyfx",
    "historyBaseUrlVideo": "https://video.inheritech.top/livehis/video/",
    "webPortBaseUrlVideo": "https://video.inheritech.top",
    "realTimeBaseUrlVideo": {
        "6001": "https://video.inheritech.top/live/video/"
    },
    "realTimeBaseUrl": "https://video.inheritech.top/live/video/",
    "realTimevideoPort": {
        "6001": "17015"
    },
    "historyPortVideo": "17019",
    "basePortIp": "39.104.179.71",
    "ListBusinessIp": "http://192.168.0.188:8418"
}

const conf = {
    dev,
    pro
};
export default conf[process.env.VUE_APP_ENV]