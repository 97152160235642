import axios from 'axios'
import Config from '../static/config'
import {
  Message
} from 'element-ui'
import NProgress from 'nprogress'

let baseURL = ''
baseURL = Config.apiBaseUrl
/** **** 创建axios实例 ******/
const service = axios.create({
  baseURL: baseURL, // api的base_url
  timeout: 60000 // 请求超时时间
})
/** **** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(
  config => {
    NProgress.start()
    config.headers = {
      'Content-Type': config['formData'] ? 'multipart/form-data; boundary=----WebKitFormBoundary6Ma8eKXgfydjpiTG' : 'application/json;charset=UTF-8',
      token: sessionStorage.getItem('token') || localStorage.getItem('token'),
    }

    if (config.method === 'post' || config.method === 'put') {
      if (!config['formData']) {
        config.data = JSON.stringify({
          ...config.data
        })
      }
    } else {
      config.params = {
        ...config.data
      }
    }
    return config
  },
  error => {
    // 请求错误处理
    NProgress.done()
    console.log(error, 'error')
    Message.error({
      message: '服务器开小差啦~'
    })
  }
)
/** **** respone拦截器==>对响应做处理 ******/
service.interceptors.response.use(
  response => {
    // 成功请求到数据
    if (response.data.code === 1103) {
      NProgress.done()
      Message.error({
        message: response.data.msg
      })
      setTimeout(() => {
        sessionStorage.removeItem('token')
        location.reload()
      }, 1500)
    } else if (response.data.code === 1203) {
      NProgress.done();
      Message.error({
        message: response.data.msg
      });
      if (sessionStorage.getItem("token") || localStorage.getItem("token")) {
        setTimeout(() => {
          sessionStorage.removeItem("token");
          localStorage.removeItem("token");
          location.reload();
        }, 1500);
      }
    } else {
      NProgress.done()
      // 这里根据后端提供的数据进行对应的处理
      return Promise.resolve(response.data)
    }
  },
  error => {
    if (error.response.status === 403) {
      // http状态码403，退出登录
      NProgress.done()
      Message.error({
        message: error.response.data.msg
      })
      if (sessionStorage.getItem('token') || localStorage.getItem('token')) {
        setTimeout(() => {
          sessionStorage.removeItem('token')
          localStorage.removeItem('token')
          location.reload()
        }, 1500)
      }
    } else {
      NProgress.done()
      Message.error({
        message: error.response.data.msg
      })
      return Promise.reject(error)
    }
  }
)
export default service
