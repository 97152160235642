import tiandituGetPlace from "@/api/lib/tiandituGetPlace.js";
/**
 * 经纬度转为地址的方法
 * @param {*} lng
 * @param {*} lat
 * @param {*} isDetials
 */
/**
 *  判断经纬度是否超出国境
 */
function isLocationOutOfChina(latitude, longitude) {
  if (
    longitude < 72.004 ||
    longitude > 137.8347 ||
    latitude < 0.8293 ||
    latitude > 55.8271
  )
    return true;
  return false;
}
export function getGDPlace(lng, lat, isDetials) {
  return new Promise(function(resolve, reject) {
    // 做一些异步操作
    let lnglatXY = [lng, lat];
    let adress = "";
    let dirText = "";
    let flag = true;
    AMap.service("AMap.Geocoder", function() {
      // 回调函数
      let geocoder = new AMap.Geocoder({ extensions: "all" });
      geocoder.getAddress(lnglatXY, (status, result) => {
        if (status === "complete" && result.info === "OK") {
          const { formattedAddress, pois } = result.regeocode;
          flag = pois && pois[0] ? true : false;
          if (flag && pois[0].direction) {
            switch (pois[0].direction) {
              case "东":
                dirText = "西";
                break;
              case "西":
                dirText = "东";
                break;
              case "北":
                dirText = "南";
                break;
              case "南":
                dirText = "北";
                break;
              case "东南":
                dirText = "西北";
                break;
              case "西南":
                dirText = "东北";
                break;
              case "东北":
                dirText = "西南";
                break;
              case "西北":
                dirText = "东南";
                break;
              default:
                dirText = "";
                break;
            }
          }

          // 获得了有效的地址信息:
          // 即，result.regeocode.formattedAddress
          // if (isDetials) {
          //   resolve(result.regeocode.addressComponent);
          // } else {
          adress = flag
            ? `${formattedAddress} ${pois[0].name} ${dirText} ${pois[0].distance}米`
            : formattedAddress;
          resolve(adress);
          // }
        } else {
          resolve("-");
        }
      });
    });
  });
}
/**
 *  将WGS-84(国际标准)转为GCJ-02(火星坐标):
 */
function transformFromWGSToGCJ(latitude, longitude) {
  var lat = "";
  var lon = "";
  var ee = 0.00669342162296594323;
  var a = 6378245.0;
  var pi = 3.14159265358979324;

  if (isLocationOutOfChina(latitude, longitude)) {
    lat = latitude;
    lon = longitude;
  } else {
    var adjustLat = transformLatWithXY(longitude - 105.0, latitude - 35.0);
    var adjustLon = transformLonWithXY(longitude - 105.0, latitude - 35.0);
    var radLat = (latitude / 180.0) * pi;
    var magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    var sqrtMagic = Math.sqrt(magic);
    adjustLat =
      (adjustLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * pi);
    adjustLon = (adjustLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * pi);
    latitude = latitude + adjustLat;
    longitude = longitude + adjustLon;
  }
  return { latitude: latitude, longitude: longitude };
}

/**
 *  将GCJ-02(火星坐标)转为WGS-84:
 */
function transformFromGCJToWGS(latitude, longitude) {
  var threshold = 0.00001;

  // The boundary
  var minLat = latitude - 0.5;
  var maxLat = latitude + 0.5;
  var minLng = longitude - 0.5;
  var maxLng = longitude + 0.5;

  var delta = 1;
  var maxIteration = 30;

  while (true) {
    var leftBottom = transformFromWGSToGCJ(minLat, minLng);
    var rightBottom = transformFromWGSToGCJ(minLat, maxLng);
    var leftUp = transformFromWGSToGCJ(maxLat, minLng);
    var midPoint = transformFromWGSToGCJ(
      (minLat + maxLat) / 2,
      (minLng + maxLng) / 2
    );
    delta =
      Math.abs(midPoint.latitude - latitude) +
      Math.abs(midPoint.longitude - longitude);

    if (maxIteration-- <= 0 || delta <= threshold) {
      return {
        latitude: (minLat + maxLat) / 2,
        longitude: (minLng + maxLng) / 2
      };
    }

    if (
      isContains(
        { latitude: latitude, longitude: longitude },
        leftBottom,
        midPoint
      )
    ) {
      maxLat = (minLat + maxLat) / 2;
      maxLng = (minLng + maxLng) / 2;
    } else if (
      isContains(
        { latitude: latitude, longitude: longitude },
        rightBottom,
        midPoint
      )
    ) {
      maxLat = (minLat + maxLat) / 2;
      minLng = (minLng + maxLng) / 2;
    } else if (
      isContains({ latitude: latitude, longitude: longitude }, leftUp, midPoint)
    ) {
      minLat = (minLat + maxLat) / 2;
      maxLng = (minLng + maxLng) / 2;
    } else {
      minLat = (minLat + maxLat) / 2;
      minLng = (minLng + maxLng) / 2;
    }
  }
}

function isContains(point, p1, p2) {
  return (
    point.latitude >= Math.min(p1.latitude, p2.latitude) &&
    point.latitude <= Math.max(p1.latitude, p2.latitude) &&
    point.longitude >= Math.min(p1.longitude, p2.longitude) &&
    point.longitude <= Math.max(p1.longitude, p2.longitude)
  );
}

function transformLatWithXY(x, y) {
  var pi = 3.14159265358979324;
  var lat =
    -100.0 +
    2.0 * x +
    3.0 * y +
    0.2 * y * y +
    0.1 * x * y +
    0.2 * Math.sqrt(Math.abs(x));
  lat +=
    ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) /
    3.0;
  lat +=
    ((20.0 * Math.sin(y * pi) + 40.0 * Math.sin((y / 3.0) * pi)) * 2.0) / 3.0;
  lat +=
    ((160.0 * Math.sin((y / 12.0) * pi) + 320 * Math.sin((y * pi) / 30.0)) *
      2.0) /
    3.0;
  return lat;
}

function transformLonWithXY(x, y) {
  var pi = 3.14159265358979324;
  var lon =
    300.0 +
    x +
    2.0 * y +
    0.1 * x * x +
    0.1 * x * y +
    0.1 * Math.sqrt(Math.abs(x));
  lon +=
    ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) /
    3.0;
  lon +=
    ((20.0 * Math.sin(x * pi) + 40.0 * Math.sin((x / 3.0) * pi)) * 2.0) / 3.0;
  lon +=
    ((150.0 * Math.sin((x / 12.0) * pi) + 300.0 * Math.sin((x / 30.0) * pi)) *
      2.0) /
    3.0;
  return lon;
}

export default function getPlace(lng, lat, isDetials) {
  return new Promise(function(resolve, reject) {
    // 做一些异步操作
    const resultLngLat = transformFromGCJToWGS(lat, lng);
    let adress = "";
    let obj = {
      postStr: JSON.stringify({
        lon: resultLngLat.longitude,
        lat: resultLngLat.latitude,
        ver: 1
      })
    };
    tiandituGetPlace(obj)
      .then(res => {
        if (res?.result) {
          const {
            province,
            city,
            county,
            town,
            road,
            poi_position,
            poi_distance,
            poi
          } = res.result?.addressComponent;
          if(province){
            adress =
            province +
            city +
            county +
            town +
            road +
            poi +
            poi_position +
            "约" +
            poi_distance +
            "米";
          resolve(adress);
          }else{
            resolve({status:true}); //默认值
          }          
        } else {
          //是否弹出弹窗，如何弹
          resolve({status:true}); //默认值
        }
      })
      .catch(err => {
        resolve("-");
      });
  });
}
